import React from 'react';
import Poster from '../components/poster';
import TopoS3 from '../utils/TopoS3';

class Print extends React.Component {
  constructor(props) {
    super(props);
    this.state = null;
  }

  componentDidMount() {
    let mapId = this.getUrlParameter('mapId');
    (async () => {
      const json = await new TopoS3().download(mapId);
      this.setState(JSON.parse(json));
    })().catch(e => console.error(e));
  }

  // TODO: this should be moved into it's own class
  getUrlParameter(name) {
    if (typeof this.props.location === 'undefined') {
      return '';
    }

    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(this.props.location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  render() {
    if (this.state) {
      return (
        <Poster
          width={`2000px`}
          height={`830px`}
          latitude={this.state.latitude}
          longitude={this.state.longitude}
          zoom={this.state.zoom}
          title={this.state.title}
          subtitle={this.state.subtitle}
          field1={this.state.field1}
          field2={this.state.field2}
          field3={this.state.field3}
          field4={this.state.field4}
          field5={this.state.field5}
          field6={this.state.field6}
          waypoints={this.state.waypoints}
          theme={this.state.theme}
        />
      );
    } else {
      return <div>Loading map...</div>;
    }
  }
}

export default Print;
